import { render, staticRenderFns } from "./Awards.vue?vue&type=template&id=516087d8"
import script from "./Awards.vue?vue&type=script&lang=js"
export * from "./Awards.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+com_7907ed7515d572276d45114f834f04a7/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports