<template>
    <section class="pt-7 pb-5" id="faq">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-8 text-center mb-3">
              <!--h5 class="text-danger">Layanan</h5-->
              <h2>FAQ</h2>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-8 col-lg-8 col-xl-8">
              <div class="text-left">
									<div class="accordion accordion-flush bg-white" id="accordionFlushExample">
                    <div class="accordion-item bg-white mb-2">
                      <h2 class="accordion-header" id="flush-heading-1">
                        <button class="accordion-button collapsed bg-white shadow py-2 fw-semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faq-1" aria-expanded="false" aria-controls="faq-1">
                          Apa itu jamitu.id?
                        </button>
                      </h2>
                      <div id="faq-1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">jamitu.id merupakan platform pengelolaan Sistem Penjaminan Mutu Internal dengan lebih mudah oleh perguruan tinggi, jamitu berbasis web dan dapat digunakan dengan berlangganan di jamitu.id</div>
                      </div>
                    </div>
                    <div class="accordion-item bg-white mb-2">
                      <h2 class="accordion-header" id="flush-heading-2">
                        <button class="accordion-button collapsed bg-white shadow py-2 fw-semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faq-2" aria-expanded="false" aria-controls="faq-2">
                          Mengapa saya harus menggunakan jamitu.id?
                        </button>
                      </h2>
                      <div id="faq-2" class="accordion-collapse collapse" aria-labelledby="flush-heading-2" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">dengan mencakup binis proses PPEPP sebagai salah satu dari 5 aspek indikator terlaksananya penjaminan mutu internal yang dikembangkan untuk mempermudah monitoring disetiap kegiatan yang dilakukan oleh unit kerja(auditee) sehingga kampus dengan mudah melihat peningkatan mutu dan visualisasi simulasi akreditasi sehingga lebih percaya diri dalam menghadapi akreditasi.</div>
                      </div>
                    </div>
                    <div class="accordion-item bg-white mb-2">
                      <h2 class="accordion-header" id="flush-heading-3">
                        <button class="accordion-button collapsed bg-white shadow py-2 fw-semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faq-3" aria-expanded="false" aria-controls="faq-3">
                          Siapa pengguna aplikasi jamitu.id?
                        </button>
                      </h2>
                      <div id="faq-3" class="accordion-collapse collapse" aria-labelledby="flush-heading-3" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">pengguna merupakan lembaga/bagian/unit di peguruan tinggi yang bertanggung jawab dalam mengelola dan menjalankan sistem penjaminan mutu internal dimana pengguna turunannya:<br>1. Auditor Internal
    menjalankan tugas sebagai audit dalam proses audit kecukupan dan audit kepatuhan<br>2. Auditee
    menjalankan tugas sebagai auditee untuk melaukan proses pelaksanaan baik pengumpulan dokomen dukung sahih dan melakukan evaluasi diri disetiap periodenya.</div>
                      </div>
                    </div>
                    <div class="accordion-item bg-white mb-2">
                      <h2 class="accordion-header" id="flush-heading-4">
                        <button class="accordion-button collapsed bg-white shadow py-2 fw-semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faq-4" aria-expanded="false" aria-controls="faq-4">
                          Bagaimana cara memiliki aplikasi penjaminan mutu internal di jamitu.id?
                        </button>
                      </h2>
                      <div id="faq-4" class="accordion-collapse collapse" aria-labelledby="flush-heading-4" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">untuk memiliki layanan espmi di jamitu.id ada 2 cara:<br>1. sewa<br>2. on premise.</div>
                      </div>
                    </div>
                    <div class="accordion-item bg-white mb-2">
                      <h2 class="accordion-header" id="flush-heading-5">
                        <button class="accordion-button collapsed bg-white shadow py-2 fw-semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faq-5" aria-expanded="false" aria-controls="faq-5">
                          Apakah semua PT terdaftar memiliki domain tersendiri?
                        </button>
                      </h2>
                      <div id="faq-5" class="accordion-collapse collapse" aria-labelledby="flush-heading-5" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">tentu saja, dengan berlanggana minimal paket layanan GOLD maka kampus bisa memiliki dengan domain sediri sesuai dengan perguruan tinggi masing-masing.</div>
                      </div>
                    </div>
                    <div class="accordion-item bg-white mb-2">
                      <h2 class="accordion-header" id="flush-heading-6">
                        <button class="accordion-button collapsed bg-white shadow py-2 fw-semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faq-6" aria-expanded="false" aria-controls="faq-5">
                          Apakah apabila terjadi bug dan update maka pelanggan harus membayar untuk item tersebut
                        </button>
                      </h2>
                      <div id="faq-6" class="accordion-collapse collapse" aria-labelledby="flush-heading-6" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">dengan menjadi pelanggan dari semua layanan kecuali on premise semua akan mendapatkan fasilitas maintenance selama layanan bahkan akan mendapatkan update terbaru sesuai dengan peningkatan fitur aplikasi maupun penyesuain bisnis proses sesuai dengan regulasi.</div>
                      </div>
                    </div>
                    <div class="accordion-item bg-white mb-2">
                      <h2 class="accordion-header" id="flush-heading-7">
                        <button class="accordion-button collapsed bg-white shadow py-2 fw-semi-bold" type="button" data-bs-toggle="collapse" data-bs-target="#faq-7" aria-expanded="false" aria-controls="faq-5">
                          Dapatkah saya memperpanjang atau mengupdate fitur berlanggan saya
                        </button>
                      </h2>
                      <div id="faq-7" class="accordion-collapse collapse" aria-labelledby="flush-heading-7" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">dengan adanya fasilitas member area bagi setiap pengguna layanan dengan mudah hanya dengan klik "perpanjang layanan" dan memilik paket yang sesuai dengan perguruan tinggi masing-masing dengan otomatis akan di perpanjang masa berlangganan setelah pembayaran dilakukan.</div>
                      </div>
                    </div>
                  </div>
								</div>
              </div>
              <div class="col-md-4 col-lg-4 col-xl-4 mb-12 text-center">
                  <img src="../assets/img/gallery/contact.png" alt="..." />
                  <a v-bind:href="wa_link" target="_blank" class="btn btn-lg fw-bold btn-success"><img src="../assets/img/gallery/whatsapp.png" alt="..." style="width:24px" /> Tanya Via Whatsapp</a>
              </div>
            </div>
        </div>
        <!-- end of .container-->

    </section>
</template>

<script>
export default {
    name : 'Faq',
    data(){
      return {
        wa_link : process.env.VUE_APP_URL_WA
      }
    }
}
</script>