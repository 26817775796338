<template>
    <section class="py-0 bg-1000">

        <div class="container">
          <div class="row justify-content-md-between justify-content-evenly py-4">
            <div class="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start">
              <p class="fs--1 my-2 fw-bold text-200">All rights Reserved &copy; eCampuz, 2022</p>
            </div>
            <div class="col-12 col-sm-8 col-md-6">
              <p class="fs--1 my-2 text-center text-md-end text-200"> Made by&nbsp;<a class="fw-bold text-white" href="https://www.ecampuz.com" target="_blank"> eCampuz</a>
              </p>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
</template>

<script>
export default {
    name : 'Footer'
}
</script>